import { BookingSnap, formatContactToAccountContact, getJobTypeFromCode, toJobPhotographerMap } from '@ht-lib/accounts-common'
import { Account, isContact, Job, JobStatus, PartialDeep, StudioClass, StudioUploadJob } from '@ht-lib/accounts-models'
import { addImageGroup } from '@ht-lib/image-groups'
import { getUnixTime, parseISO } from 'date-fns'

export type JobFormSectionNames = 'jobCompare' | 'jobType' | 'contactVenue' | 'termDates' | 'photographers' | 'note'

export type SectionNames = JobFormSectionNames | 'selector' | 'images' | 'done'

export type JobFormUpdate = (job: PartialDeep<Job>) => void

export async function addImageGroupFromClass (sClass: StudioClass, userCreatedId: string): Promise<void> {
  const longRefs = sClass.images.map(x => x.longRef)
  await addImageGroup({
    keys: {
      docket: sClass.name
    },
    longRefs,
    notes: sClass.note,
    userCreatedId
  })
}

export async function makeJobFromUpload (upload: StudioUploadJob, bookingSnap: BookingSnap, account: Account, jobType: string): Promise<Job> {
  const aimsData = await getJobTypeFromCode(jobType)
  const booking = bookingSnap.data()
  // Use firestore to generate an ID. this will be refactored later when we can remove the Class wrappers around firestore

  const takeDate = parseISO(upload.studio.job.date)
  const job: Job = {
    nexusJobId: null,
    isCommittedToLab: false,
    hasCommissionValues: false,
    hasAimsJobNumber: false,
    hasBeenQCed: false,
    hasAccountApproval: false,
    account: {
      code: booking.accountCode,
      contact: isContact(booking.deliveryContact) ? formatContactToAccountContact(booking.deliveryContact) : null,
      dispatchAddress: booking.deliveryAddress ?? null,
      fullTerm: null,
      halfTerm: null,
      headTeacherName: null,
      name: account.name,
      holder: {
        id: account.accountHolderContactInfo.id,
        code: account.accountHolderContactInfo.photographerId ?? '',
        commission: null,
        name: account.accountHolderContactInfo.fullName ?? null
      }
    },
    market: aimsData?.market ?? 'S',
    imageCount: 0,
    onHold: false,
    userViewing: null,
    availableOnline: true,
    accountCommissionWeb: aimsData?.commissionToAccount.webOrders ?? null,
    accountCommissionMod: aimsData?.commissionToAccount.modOrders ?? null,
    accountId: booking.accountId,
    aimsJobNumber: null,
    bookingId: bookingSnap.id,
    createUnixTimestamp: getUnixTime(new Date()),
    importedCount: 0,
    outOfHours: false,
    usedNameSearch: upload.studio.job.usedNameSearch ?? false,
    isDataLinked: upload.studio.job.dataLinked,
    jobType,
    notes: '',
    nexusImportDescription: null,
    nexusImportStatus: null,
    importToNexusProgressId: null,
    photographersMap: toJobPhotographerMap(booking.photographersMap),
    status: JobStatus.DRAFT,
    takeUnixTimestamp: getUnixTime(takeDate),
    committedToLabUnixTimestamp: null,
    aimsNumAddedUnixTimestamp: null,
    takeDate
  }
  return job
}
