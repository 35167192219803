














































import { Component, Prop, Vue } from 'vue-property-decorator'

import { formatDate } from '@ht-lib/accounts-common'
import { isSameDay, fromUnixTime, parseISO } from 'date-fns'
import { StudioJob, Booking } from '@ht-lib/accounts-models'
import { labUploadDataProvider } from '../../bookings'

interface Icon {
  col: string
  icon: string
}
@Component({
  name: 'JobCompare',
  components: {}
})
export default class extends Vue {
  @Prop({ type: Object, required: false }) booking!: Booking
  @Prop({ required: true }) update!: (b: boolean) => void
  @Prop({ required: true }) confirm!: boolean
  @Prop({ type: Object, required: true }) studioJob!: StudioJob
  @Prop({ type: Boolean, required: false, default: false }) isLabUploader!: boolean

  formatDate = formatDate

  icon (b: boolean): Icon {
    return b ? { icon: 'check_circle', col: 'text-positive' } : { icon: 'error', col: 'text-warning' }
  }

  isSame (a: string, b: string): boolean {
    return a.toLowerCase().trim() === b.toLowerCase().trim()
  }

  compareGet (studio: string, booking: string) {
    if (this.isSame(studio, booking)) return ''
    return `* ${ studio }`
  }

  get hasMismatch () {
    return this.nameText || this.codeText || this.dateText
  }

  get nameText () {
    return this.compareGet(this.studioJob.name, this.booking.accountName)
  }

  get codeText () {
    return this.compareGet(this.studioJob.code, this.booking.accountCode)
  }

  get currentPhotographerCode () {
    return this.isLabUploader
      ? labUploadDataProvider.getPhotographer().photographerId ?? 'No Code'
      : this.$auth.user.profile?.photographerId ?? 'No Code'
  }

  get photoCode () {
    return this.compareGet(this.studioJob.job.classes[0].photographerCode, this.currentPhotographerCode)
  }

  get dateText () {
    if (isSameDay(parseISO(this.studioJob.job.date), fromUnixTime(this.booking.startUnixTimestamp))) {
      return ''
    }
    return `* ${ formatDate(this.studioJob.job.date) }`
  }
}
