/* eslint-disable @typescript-eslint/no-explicit-any */

interface FileSystemEntry {
  readonly name: string
  readonly fullPath: string
  readonly isDirectory: boolean
  readonly isFile: boolean
}

// eslint-disable-next-line no-use-before-define
type Entry = FileSystemFileEntry | FileSystemDirectoryEntry

interface FileSystemFileEntry extends FileSystemEntry {
  file: (
    successcallback: (file: File) => void,
    errorCallback?: (err: any) => void
  ) => void
}

interface FileSystemDirectoryReader {
  readEntries: (
    successcallback: (entries: Entry[]) => void,
    errorCallback?: (err: any) => void
  ) => void
}

interface FileSystemDirectoryEntry extends FileSystemEntry {
  createReader: () => FileSystemDirectoryReader
  getDirectory: () => FileSystemDirectoryEntry
  getFile: () => FileSystemFileEntry
}

export const isDirectory = (entry: FileSystemEntry): entry is FileSystemDirectoryEntry => entry.isDirectory

async function getEntries (entry: FileSystemDirectoryEntry): Promise<Entry[]> {
  const more = async (): Promise<Entry[]> => await new Promise<Entry[]>((resolve, reject) => reader.readEntries(resolve, reject))
  const reader = entry.createReader()
  let entries: Entry[] = []
  let buffer: Entry[]

  do {
    // eslint-disable-next-line no-await-in-loop
    buffer = await more()
    entries = entries.concat(buffer)
  } while (buffer.length > 0)

  return entries
}

async function scanSubDirectory (entry: FileSystemDirectoryEntry): Promise<Entry[]> {
  // console.log(`D: ${ entry.fullPath }`)
  return await getEntries(entry)
}

async function scanFile (entry: FileSystemFileEntry): Promise<File> {
  // console.log(`F: ${ entry.fullPath }`)
  return await new Promise<File>((resolve, reject) => entry.file(resolve, reject))
}

async function scanDirectory (entry: Entry, files: File[]): Promise<void> {
  if (isDirectory(entry)) {
    await scanSubDirectory(entry).then(async x => await Promise.all(x.map(async y => await scanDirectory(y, files))))
    return
  }

  const file = await scanFile(entry)
  if (filterFile(file)) {
    files.push(file)
  }
}

export function filterFiles (files: File[]): File[] {
  return files.filter(filterFile)
}

export function filterFile (file: File): boolean {
  return file.type === 'image/jpeg' || (file.type === 'application/json' && file.name.startsWith('job') && file.name.endsWith('.json'))
}

export async function getFiles (entry: Entry): Promise<File[]> {
  const files: File[] = []
  await scanDirectory(entry, files)

  console.log(`Scanned ${ files.length } files`)
  return files
}
