import { render, staticRenderFns } from "./Selector.vue?vue&type=template&id=45216a1c&scoped=true&lang=pug&"
import script from "./Selector.vue?vue&type=script&lang=ts&"
export * from "./Selector.vue?vue&type=script&lang=ts&"
import style0 from "./Selector.vue?vue&type=style&index=0&id=45216a1c&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45216a1c",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn});
